<template>
  <v-list-item 
    :prepend-icon="menu.icon" 
    :title="$t(menu.title, locale)" 
    :value="menu.path"
    @click="onClick(menu.path)"
    :active="active"
    color="#00b6bd"
    variant="plain"
    :style="{ backgroundImage: active ? `url('${menuImage}')` : null, fontWeight: active ? '700 !important' : null }"
    style="background-size: cover;"
  />
</template>
<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    menuImage () {
      return require('@/assets/menu.png')
    },
  },
}
</script>
<style>
</style>

