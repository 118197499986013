<template>
    <router-view :locale="locale" />
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Public',
  computed: {
    ...mapState(['locale']),
  },
}
</script>

