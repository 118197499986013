export default {
    common: {
      add: 'Add',
      edit: 'Edit',
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Delete',
      search: 'Search',
      back: 'Back',
      complete: 'Complete',
      ok: 'OK',
      clear: 'Clear',
      config: 'Config',
      confirm: 'Confirm',
      all: 'All',
      close: 'Close',
      home: 'Home',
      required: 'Required',
      download: 'Download',
      select: 'Select',
      daysOfWeek: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
    },
    deleteButton: {
      message: 'Are you sure you want to delete this item?',
    },
    login: {
      account: 'Account',
      email: 'Email',
      emailPlaceholder: 'Email address',
      password: 'Password',
      passwordPlaceholder:'Enter your password',
      login: 'Log In',
      logout: 'Logout',
      signIn: 'Sign in',
      changePassword: 'Change password',
      oldPassword: 'Old password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      forgotLoginPassword: 'forgot login password?',
      orLogin: 'or login',
      passwordRecovery: 'Password recovery',
      messages: {
        info: 'The page you were looking for does not exist',
        userNotFound: 'Email or password not found',
        changePasswordError: 'Error',
        required: 'Required',
        max20: 'Must be less than 20 characters',
        passwordNotMatch: 'Password not match',
        strengthNotValid: 'The password is at least 8 characters long, one uppercase letter, one lowercase letter, one digit and one special character.',
        passwordRecoverMailSend: 'Email sent successfully',
      },
    },
    menuForm: {
      name: 'Name',
      nameRequired: 'Name is required',
      max50: 'Name must be less than 50 characters',
    },
    system: {
      title: 'System',
      goToManagement: 'Go to management',
      management: 'Management',
      config: {
        title: 'Config',
        co2: 'CO2',
        directCost: 'Direct cost(€/kg)',
      },
      dashboard: {
        title: 'Dashboard',
      },
      workspace: {
        title: 'Workspaces',
        name: 'Name',
        deleteWeightData: 'Delete weight data',
        nameRequired: 'Name is required',
        max50: 'Name must be less than 50 characters',
        subjectKeyMets: 'Subject',
        emailKeyMets: 'Email',
        relationsKeys: {
          title: 'Relations keys',
          name: 'Name',
        },
        shifts: {
          title: 'Services',
          name: 'Name',
        },
        analyticsCards: {
          title: 'Analytics cards',
          button: 'Button',
          custom: 'Custom',
          name: 'Title',
        },
      },
      users: {
        title: 'Users',
        email: 'Email',
        emailRequired: 'Email is required',
        max50: 'Email must be less than 50 characters',
        password: 'Password',
        passwordRequired: 'Password is required',
        permissions: 'Permissions',
        emailNotValid: 'Email not valid',
        emailAlreadyExists: 'Email already exists',
        welcomePath: 'Rute by default',
        required: 'Required',
      },
    },
    management: {
      workspace: 'Workspace',
      dashboard: {
        title: 'Dashboard',
        messages: {
          noDataToday: 'No data today',
          daysAgo: 'Last data from %% days ago',
        },
      },
      diners: {
        title: 'Diners',
        shift: 'Shift',
        date: 'Date',
        value: 'Value',
        messages: {
          valueRequired: 'Value is required',
          isNotNumber: 'Must be a number',
          greaterThan0: 'Must be greater than 0',
          duplicateRecord: 'Duplicate record',
        },
      },
      keys: {
        title: 'Keys',
        ingredients: 'Ingredients',
        ingredient: 'Ingredient',
        percentage: 'Porcentage',
        dishes: 'Dishes',
        others: 'Others',
        name: 'Name',
        priceKg: 'Price/Kg',
        messages: {
          nameRequired: 'Name is required',
          max50: 'Name must be less than 50 characters',
          isNotNumber: 'Must be a number',
        },
      },
      buttons: {
        title: 'Buttons',
        name: 'Name',
        defaultHomeOnSave: 'Default home on save',
        fieldsRequired: '(*) indicates required field',
        relationsKeys: 'Relations keys',
        dishesKeys: 'Dishes',
        othersKeys: 'Otros',
        ingredientsKeys: 'Ingredients',
        weightButton: 'Weight button',
        preConsumerButton: 'Pre-Consumer button',
        tara: 'Tare',
        net: 'Net', 
        gross: 'Gross',
        price: 'Price',
        showHide: 'Show hide',
        availability: {
          temp: 'Temporal',
          noTemp: 'No temporal',
          alt: 'Alternation',
          dateStart: 'Date start',
        },
        messages: {
          isNotNumber: 'Must be a number',
          max150: 'Name must be less than 150 characters',
          required: 'Required',
          noEndWeightButtons: 'Unmarked buttons for weighings',
          allServices: 'Button visible in all services',
          filterServices: 'Button visible on selected services',
        },
      },
      weightCollection: {
        title: 'Weight collection',
        selectMenu: 'Selecciona menú',
        selectProduct: 'Selecciona producto',
        weigh: 'Weigh',
        messages: {
          title: 'Do you want to continue?',
          variation: 'A variation from the normal mean has been detected.',
          weightExist: 'A previous weight has already been recorded of %%.',
        },
      },
      stats: {
        title: 'Data',
      },
      analytics: {
        title: 'Analytics',
        subtitle: 'Summary of food waste',
        noDataToday: 'No data for today',
        dataFromTo: 'Data from min to max',
        average: 'Average',
        weight: 'Weight',
        diff: 'Difference in kg',
        percentage: 'Percentage',
        cards: {
          averageDailyFoodWaste: 'Average daily food waste',
          foodWastePerCover: 'Food waste per cover',
          preConsumerFoodWaste: 'Pre-consumer food waste',
          carbonReduction: 'Carbon footprint associated with waste',
        },
        compare: {
          title: 'Comparator between two specific dates',
          subtitle: '',
          daysAgo: '%% days ago',
          date: 'Date',
        },
        messages: {
          noData: 'No data for selected filters',
        },
      },
    },
    notFound: {
      getMeOutOfHere: 'Get me out of here!',
    },
}
