<template>
  <v-card class="elevation-0" style="text-align: left;">
    <v-layout>
      <v-navigation-drawer
        permanent
        class="bg-red"
        theme="dark"
      >
        <!--v-list v-model="selectedMenu">
          <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/women/85.jpg"
            title="Sandra Adams"
            subtitle="sandra_a88@gmailcom"
          />
        </v-list-->

        <v-divider/>

        <v-list 
          density="compact" 
          nav
        >
          <v-list-item 
            v-for="item of menus"
            :key="item.path"
            :prepend-icon="item.icon" 
            :title="$t(item.title, locale)" 
            :value="item.path"
            @click="handleGoTo(item.path)"
          />
        </v-list>        
        <template v-slot:append>
          <div class="pa-2">
            <v-btn 
              block 
              variant="tonal"
              @click="handleGoTo('/system/')"
            >
              {{ $t('system.title', locale) }}
            </v-btn>
          </div>
          <div class="pa-2">
            <v-btn 
              block 
              variant="tonal"
              @click="handleGoTo('/management/')"
            >
              {{ $t('system.management', locale) }}
            </v-btn>
          </div>
          <div class="pa-2">
            <v-btn block @click="handleGoTo('/login')">
              {{ $t('login.logout', locale) }}
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main>
        <router-view :locale="locale" />
      </v-main>
    </v-layout>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'System',
  data:  () => ({
    selectedMenu: null,
    menus: [
    /*  { 
        icon: 'mdi-view-dashboard',
        title: 'system.dashboard.title',
        path: '/system/'
      },
      { 
        icon: 'mdi-folder',
        title: 'system.workspace.title',
        path: '/system/workspaces/'
      },
      { 
        icon: 'mdi-account-multiple',
        title: 'system.users.title',
        path: '/system/users/'
      },*/
    ],
  }),
  computed: {
    ...mapState(['locale']),
  },
  methods: {
    handleGoTo (v) {
      this.$router.push(v)
    },
  },
}
</script>
<style scoped>
.v-layout {
  background-color: #CCCCCC80;
}

</style>

