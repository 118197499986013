<template>
  <v-card class="elevation-0" style="text-align: left;">
    <v-toolbar
      color="white"
      :style="{ backgroundImage: `url('${divider}')` }"
      style="background-position: bottom;background-repeat: repeat-x;"
    >
      <v-spacer/>
        <user-config
          :locale="locale"
          :toolbar="true"
        />
    </v-toolbar>
    <v-layout>
      <v-navigation-drawer
        permanent
        theme="light"
        color="white"
      >
    <v-toolbar
      color="white"
      :style="{ backgroundImage: `url('${divider}')` }"
      style="background-position: bottom;background-repeat: repeat-x;"
    >
      <v-img :src="logo" />
    </v-toolbar>
        <select-workspace 
          :locale="locale"
        />
        <!--v-list v-model="selectedMenu">
          <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/women/85.jpg"
            title="Sandra Adams"
            subtitle="sandra_a88@gmailcom"
          />
        </v-list-->


        <v-list 
          density="compact" 
        >
          <menu-item
            v-for="menu of menus"
            :key="menu.path"
            :menu="menu"
            :onClick="handleGoTo"
            :active="actualPath === menu.path"
            :locale="locale"
          />
          <!--v-list-item 
            :prepend-icon="item.icon" 
            :title="$t(item.title, locale)" 
            :value="item.path"
            @click="handleGoTo(item.path)"
            :active="actualPath === item.path"
            color="#00b6bd"
          /-->
        </v-list>        
        <template v-slot:append>
          <!--div class="pa-2">
            <v-btn block text @click="handleGoTo('/login')">
              {{ $t('login.logout', locale) }}
            </v-btn>
          </div-->
          <v-list 
            density="compact" 
          >
            <user-config
              :locale="locale"
            />
            <v-list-item 
              prepend-icon="mdi-logout" 
              :title="$t('login.logout', locale)" 
              @click="handleGoTo('/login')"
              variant="plain"
            />
          </v-list>        
        </template>
      </v-navigation-drawer>

      <v-main>
        <router-view 
          v-if="managementWorkspaceID" 
          :locale="locale" 
          :workspaceID="managementWorkspaceID" 
        />
      </v-main>
    </v-layout>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import sec from '@/services/sec'
import SelectWorkspace from './SelectWorkspace'
import UserConfig from './UserConfig'
import MenuItem from './Menu'
export default {
  name: 'Management',
  components: {
    SelectWorkspace,
    UserConfig,
    MenuItem,
  },
  data:  () => ({
    selectedMenu: null,
    actualPath: null, // used to set active nav
    menus: [],
  }),
  computed: {
    ...mapState(['locale', 'managementWorkspaceID']),
    userID () {
      return sec.getUser().ID
    },
    logo () {
      return require('@/assets/logoSidebar.png')
    },
    divider () {
      return require('@/assets/divider.png')
    },
  },
  watch: {
    '$router.currentRoute.value.path' (v) {
      this.actualPath = v
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.menus = []
      
      if (!this.userID) return 

      api.getAll(`/users/${this.userID}/sidebar-menus`)
        .then(response => {
          this.menus = response ? response : []
          const path = this.$router.currentRoute.value.path
          if (this.menus.filter(x => x.path === path).length > 0) {
            this.actualPath = path
          } else {
            if (this.menus.length > 0) {
              this.handleGoTo(this.menus[0].path)
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.menus = []
        })
    },
    handleGoTo (v) {
      this.actualPath = v
      this.$router.push(v)
    },
  },
}
</script>
<style>
.v-layout {
  /*background-color: #CCCCCC80;*/
}
.v-navigation-drawer {
  border: 0;
}
.v-btn {
  text-transform: none !important;
  border-radius: 10px !important;
}
</style>

