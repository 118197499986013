import { createVuetify } from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'

export default createVuetify({
  directives,
  components: {
    ...components,
    ...labsComponents,
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#566573',
          //secondary: 'red',//colors.red.lighten4, // #FFCDD2
        }
      },
      admin: {
        dark: false,
        colors: {
          primary: '#FFFF00',
          //secondary: 'red',//colors.red.lighten4, // #FFCDD2
        }
      },
    },
  },
  /*
  theme: {
    defaultTheme: 'odj',
    themes: {
      odj: {
        dark: false,
        variables: {}, // ✅ this property is required to avoid Vuetify crash
       
        colors: {
          //green: '#00ff00' // cannot use primary color names here, so use a custom color name (such as 'greenish')
          greenish: '#03DAC5',

          // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
          background: '#ccc',
          surface: '#212121',
          primary: '#00ff00',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC5',
          'secondary-darken-1': '#03DAC5',
          error: '#CF6679',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00'
        },
      }
    }
  }*/
})

