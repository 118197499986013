<template>
  <div v-if="user">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="end"
    >
      <template v-slot:activator="{ props }">
        <v-list-item 
          v-if="!toolbar"
          prepend-icon="mdi-cog-outline" 
          :title="$t('common.config', locale)" 
          v-bind="props"
          variant="plain"
        />
        <v-list-item 
          v-else
          append-icon="mdi-chevron-down" 
          :title="user.Email" 
          v-bind="props"
          variant="plain"
        />
        <!--v-btn
          color="white"
          v-bind="props"
          block
          small
          variant="tonal"
        >
          {{$t('common.config', locale)}}
        </v-btn-->
      </template>

      <v-card min-width="400" style="padding:10px;">
        <v-list>
          <!--v-list-item
            prepend-avatar="https://cdn.vuetifyjs.com/images/john.jpg"
            title="John Leider"
            subtitle="Founder of Vuetify"
          >
            <template v-slot:append>
              <v-btn
                variant="text"
                :class="fav ? 'text-red' : ''"
                icon="mdi-heart"
                @click="fav = !fav"
              ></v-btn>
            </template>
          </v-list-item-->
          <v-list-item style="text-align: center;"
            :title="user.Email"
          >
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list>
          <v-list-item style="text-align: center;">
            <v-btn-toggle
              v-model="selectedLocale"
              color="deep-purple-accent-3"
              group
              block
            >
              <v-btn value="es" @click="handleChangeLocale">
                es
              </v-btn>

              <v-btn value="en" @click="handleChangeLocale">
                en
              </v-btn>
            </v-btn-toggle>
          </v-list-item>
          <!--v-list-item>
            <v-switch
              v-model="message"
              color="purple"
              label="Enable messages"
              hide-details
            ></v-switch>
          </v-list-item>

          <v-list-item>
            <v-switch
              v-model="hints"
              color="purple"
              label="Enable hints"
              hide-details
            ></v-switch>
          </v-list-item-->
        </v-list>
        <v-divider v-if="paths.length > 0"/>
        <v-list>
          <v-list-item
            v-for="item of paths"
            :key="item.path"
          >
            <v-btn
              @click="handleGoTo(item.Path)"
              :color="item.Color"
              block
            >
              {{ item.Name }} 
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider />

        <v-card-actions v-if="!showChangePassword">
          <v-spacer />
          <v-btn 
            block
            color="deep-purple-accent-3"
            @click="showChangePassword = true"
          >
            {{$t('login.changePassword', locale)}}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="showChangePassword" style="padding:10px;">          
          <v-form ref="form">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="oldPassword"
                  :append-inner-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showOldPassword ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('login.oldPassword', locale)"
                  :rules="oldPasswordRules"
                  counter
                  @click:append-inner="showOldPassword = !showOldPassword"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="newPassword"
                  :append-inner-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPassword ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('login.newPassword', locale)"
                  counter
                  :rules="newPasswordRules"
                  @click:append-inner="showNewPassword = !showNewPassword"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="confirmPassword"
                  :append-inner-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('login.confirmPassword', locale)"
                  :rules="confirmPasswordRules"
                  counter
                  @click:append-inner="showConfirmPassword = !showConfirmPassword"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-spacer></v-spacer>

                <v-btn
                  @click="showChangePassword = false"
                >
                  {{ $t('common.cancel', locale) }}
                </v-btn>
                <v-btn
                  color="deep-purple-accent-3"
                  @click="handleChangePassword"
                >
                  {{ $t('common.save', locale) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
        <!--v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            variant="text"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="menu = false"
          >
            Save
          </v-btn>
        </v-card-actions-->
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import api from '@/services/api'
import sec from '@/services/sec'
export default {
  props: {
    locale: {
      type: String,
      requried: true,
    },
    toolbar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    user: null,
    paths: [],
    selectedLocale: null,
    showChangePassword: false,
    oldPassword: null,
    showOldPassword: false,
    newPassword: null,
    showNewPassword: false,
    confirmPassword: null,
    showConfirmPassword: false,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  computed: {
    userID () {
      return sec.getUser().ID
    },
    oldPasswordRules () {
      return [
        v => !!v || this.$t('login.messages.required', this.locale),
      ]
    },
    newPasswordRules () {
      return [
        v => !!v || this.$t('login.messages.required', this.locale),
        v => (v && v.length <= 20) || this.$t('login.messages.max20', this.locale),
        v => (v && sec.checkPassword(v)) || this.$t('login.messages.strengthNotValid', this.locale),
        v => (v && v === this.confirmPassword) || this.$t('login.messages.passwordNotMatch', this.locale),
      ]
    },
    confirmPasswordRules () {
      return [
        v => !!v || this.$t('login.messages.required', this.locale),
        v => (v && v.length <= 20) || this.$t('login.messages.max20', this.locale),
        v => (v && v === this.newPassword) || this.$t('login.messages.passwordNotMatch', this.locale),
      ]
    },
  },
  mounted () {
    this.selectedLocale = this.locale ? this.locale : 'es'
    this.handleGetUser()
    this.handleGetPaths()
  },
  methods: {
    handleGetPaths () {
      if (!this.userID) return 
      api.getAll(`/users/${this.userID}/paths`)
        .then(response => {
          this.paths = response
        })
        .catch(error => {
          console.log(error)
          this.paths = []
        })
    },
    handleGetUser () {
      if (!this.userID) return 
      api.getAll(`/users/${this.userID}/info`)
        .then(response => {
          this.user = response
        })
        .catch(error => {
          console.log(error)
          this.menus = []
        })
    },
    handleGoTo (v) {
      this.$router.push(v)
    },
    handleChangeLocale () {
      this.$store.state.locale = this.selectedLocale
    },
    async handleChangePassword () {
      const { valid } = await this.$refs.form.validate()
      if (!valid) return

      api.changePassword({ userID: this.userID, oldPassword: this.oldPassword, newPassword: this.newPassword  })
        .then(() => {
          this.showChangePassword = false
          this.menu = false
        })
        .catch(error => {
          console.log(error)
          const msg = this.$t(`login.messages.changePasswordError`, this.locale)
          alert(msg)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

