import { createI18n } from 'vue-i18n'
//import messages from './messages'
import es from './es'
import en from './en'
export default createI18n({
  locale: 'es', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages: {
    es,
    en
  },
  //messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})
