import { createRouter, createWebHashHistory } from 'vue-router'
import api from '@/services/api'
import sec from '@/services/sec'

const routes = [
  { meta: { area: 'autologin', requiresAuth: true }, name: 'AutoLoginPanel', path: '/panel/', component: () => import('./views/autoLogin/panel/Index.vue') },
  { meta: { area: 'autologin', requiresAuth: true }, name: 'AutoLoginPanelToken', path: '/panel/:token', component: () => import('./views/autoLogin/panel/Index.vue') },

  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementDashboard', path: '/management/', component: () => import('./views/management/dashboard/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementDiners', path: '/management/diners', component: () => import('./views/management/diners/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementKeys', path: '/management/keys', component: () => import('./views/management/keys/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementButtons', path: '/management/buttons', component: () => import('./views/management/buttons/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementWeightCollection', path: '/management/weight-collection', component: () => import('./views/management/weightCollection/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementWeightCollectionScale', path: '/management/weight-collection/:key', component: () => import('./views/management/weightCollection/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementStats', path: '/management/stats', component: () => import('./views/management/stats/Index.vue') },
  { meta: { area: 'management', requiresAuth: true }, name: 'ManagementAnalytics', path: '/management/analytics', component: () => import('./views/management/analytics/Index.vue') },

  { meta: { area: 'public', requiresAuth: false }, name: 'Login', path: "/login", component: () => import('./views/public/Login.vue') },
  { meta: { area: 'public', requiresAuth: false }, name: 'PasswordRecovery', path: "/password-recovery/:token", component: () => import('./views/public/PasswordRecovery.vue') },
  { meta: { area: 'public', requiresAuth: false }, name: 'NotFound', path: "/:catchAll(.*)", component: () => import('./views/public/NotFound.vue') },
  { meta: { area: 'public', requiresAuth: false }, name: 'Home', path: '/', component: () => import('./views/public/Home.vue') },

  { meta: { area: 'system', requiresAuth: true }, name: 'SystemDashboard', path: "/system/", component: () => import('./views/system/Index.vue') },
  { meta: { area: 'system', requiresAuth: true }, name: 'SystemWorkspaces', path: "/system/workspaces/", component: () => import('./views/system/workspaces/Index.vue') },
  { meta: { area: 'system', requiresAuth: true }, name: 'SystemUsers', path: "/system/users/", component: () => import('./views/system/users/Index.vue') },

  { meta: { area: 'development', requiresAuth: true }, name: 'DevelopmentDashboard', path: "/development/", component: () => import('./views/development/Index.vue') },
]

const history = createWebHashHistory()

const router = createRouter({
history,
routes,
})

router.beforeEach((to, from, next) => {
  
  if (to.name === 'Login') api.setToken('') // if go to login clear token "logout"
  // load user if null
  
  if (to.meta.area === 'autologin' && to.params.token) api.autologin(to.params.token)

  if (to.meta.area !== 'public') {
    const user = sec.getUser()
    // INI check is system
    if (to.meta.area === 'system' 
      && (!user || user.Areas.indexOf('4a6501ca-0ae6-4749-a954-48cea4740c8f') === -1)) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
    // END check is system
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = sec.getUser()
    if (!api.getToken() || !user) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else next()
  } else next()
})

export default router

