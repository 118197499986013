import axios from 'axios'
import jsSHA from 'jssha'
import router from '@/router'
import store from '@/plugins/store/index'
import sec from './sec'

export default {
  getArea() {
    return router.currentRoute.value.meta.area
  },
  prepareURL (path, noArea) {
    const baseURL = process.env.VUE_APP_ENV !== 'production' ?  
      process.env.VUE_APP_API : store.state.environment === 'production' ?
      'https://production-api.effiwaste.es/v1' : 'https://sandbox-api.effiwaste.es/v1'
    if (noArea)
      return `${baseURL}${path}`
    else
      return `${baseURL}/${this.getArea()}${path}`
  },
  getURL (path) {
    const token = localStorage.getItem(`jwt`)
    return `${this.prepareURL(path)}?token=${token}`
  },
  preparePagination (startIndex = 0, itemsPerPage = 10000, searchValue = '') {
    return `?start_index=${startIndex}&items_per_page=${itemsPerPage}&search_value=${searchValue ? searchValue : ''}`
  },
  checkError (v) {
    if (v.response && v.response.status === 403) router.push('/login')
    else {
      return v
    }
  },
  convertToHash (v) {
    const hashObj = new jsSHA('SHA-512', 'TEXT', {numRounds: 1});
    hashObj.update(v);
    return hashObj.getHash('HEX')
  },
  // INI localStorage
  setToken (v) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${v}`
    localStorage.setItem(`jwt`, v ? v : '')
    return
  },
  getToken () {
    const aux = localStorage.getItem(`jwt`)
    axios.defaults.headers.common['Authorization'] = `Bearer ${aux}` 
    return aux && aux.length > 0 ? aux : null
  },
  // END localStorage
  changePassword (v) {
    const url = this.prepareURL(`/users/${v.userID}/change-password`)
    return axios.put(url, { oldPassword: this.convertToHash(v.oldPassword), newPassword: this.convertToHash(v.newPassword) })
      .then(response => response ? response.data : null)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  autologin (v) {
    const url = this.prepareURL('/autologin/token-data', true)
    return axios.post(url, { token: v })
      .then(response => {
        this.setToken(v)
        sec.setUser(response.data)
        if (response.data) router.push('/panel/')
      })
      .catch(error => {
        this.checkError(error)
      })
  },
  login (v) {
    const url = this.prepareURL('/login')
    return axios.post(url, { email: v.email, password: this.convertToHash(v.password) })
      .then(response => {
        const { token, welcomePath, user } = response.data
        this.setToken(token)
        sec.setUser(user)
        if (token) router.push(welcomePath && welcomePath.length > 0 ? welcomePath :'/')
      })
      .catch(error => {
        throw(error)
      })
  },
  passwordRecovery (v) {
    const url = this.prepareURL('/login/password-recovery')
    return axios.post(url, { email: v.email })
      .then(() => {
      })
      .catch(error => {
        throw(error)
      })
  },
  getAll (path, startIndex, itemsPerPage, searchValue) {
    let url = this.prepareURL(path)
    url = url.concat(this.preparePagination(startIndex, itemsPerPage, searchValue))
    return axios.get(url)
      .then(response => response ? response.data : null)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  getItem (path) {
    const url = this.prepareURL(path)
    return axios.get(url)
      .then(response => response ? response.data.shift() : null)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  addItem (path, v) {
    const url = this.prepareURL(path)
    return axios.post(url, v)
      .then(response => response)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  updateItem (path, v) {
    const url = this.prepareURL(path)
    return axios.patch(url, v)
      .then(response => response)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  cloneItem (path, v) {
    const url = this.prepareURL(path).concat('/clone')
    return axios.put(url, v)
      .then(response => response)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  deleteItem (path) {
    const url = this.prepareURL(path)
    return axios.delete(url)
      .then(response => response)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  put (path, v) {
    const url = this.prepareURL(path)
    return axios.put(url, v)
      .then(response => response)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
  getExcel (path) {
    const url = this.prepareURL(path)
    return axios.put(url, { responseType: 'blob' })
      .then(response => response)
      .catch(error => {
        throw(this.checkError(error))
      })
  },
}

