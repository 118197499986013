<template>
  <custom-alert />
  <div v-show="isSandbox || isDevelopment" class="environment-tag" :style="{ backgroundColor: isDevelopment ? '#FFC30080' : isSandbox ? '#FF310380' : '#03FE5B80' }">{{ isDevelopment ? 'DEV' : isSandbox ? 'SANDBOX' : 'ALPHA' }}</div>
  <public v-if="layout === 'public'" />
  <auto-login v-else-if="layout === 'autologin'" />
  <system v-else-if="layout === 'system'" />
  <development v-else-if="layout === 'development'" />
  <management v-else-if="layout === 'management'" />
</template>
<script>
import Public from '@/layout/Public.vue'
import AutoLogin from '@/layout/AutoLogin.vue'
import System from '@/layout/System.vue'
import Development from '@/layout/Development.vue'
import Management from '@/layout/management/Index.vue'
import CustomAlert from '@/components/CustomAlert'
export default {
  name: 'App',
  components: {
    Public,
    AutoLogin,
    Development,
    System,
    Management,
    CustomAlert,
  },
  computed: {
    layout () {
      const { area } = this.$router.currentRoute.value.meta
      return area ? area : 'public'
    },
    isDevelopment () {
      return process.env.VUE_APP_ENV === 'development'
    },
    isSandbox () {
      return window.location.host.indexOf('sandbox') >= 0
    },
  },
  mounted () {
    this.$store.state.environment = this.isSandbox ? 'sandbox' : 'production'
  },
}
</script>

<style>
body {
  /*background-color: #CCCCCC80;*/
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.environment-tag {
  background-color: #FF310380;
  position: fixed;
  z-index: 1000;
  width: 150px;
  padding: 5px 0;
  top: 20px;
  right: -40px;
  rotate: 45deg;
  font-weight: 700;
}
</style>
