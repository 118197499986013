<template>
  <v-autocomplete
    v-if="workspaces.length > 1"
    v-model="selectedWorkspace"
    :label="$t('management.workspace', locale)"
    :items="workspaces"
    hide-details
    item-title="Name"
    item-value="ID"
    density="compact"
    @update:modelValue="handleChange"
  />
</template>
<script>
import api from '@/services/api'
import sec from '@/services/sec'
export default {
  name: 'SelectWorkspace',
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    workspaces: [],
    selectedWorkspace: null,
  }),
  computed: {
    userID () {
      return sec.getUser().ID 
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.workspaces = []
      if (!this.userID) return
      api.getAll(`/users/${this.userID}/workspaces`)
        .then(response => {
          this.workspaces = response.data

          if (!this.selectedWorkspace) {
            const aux = sec.getManagementWorkspace()
            if (aux && this.workspaces.filter(x => x.ID === aux).length > 0) {
              this.selectedWorkspace = aux
              this.handleChange()
            } else if (this.workspaces.length > 0) {
              this.selectedWorkspace = this.workspaces[0].ID
              this.handleChange()
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleChange () {
      sec.setManagementWorkspace(this.selectedWorkspace)
      //this.$router.push('/management/')
    },
  },
}
</script>

