<template>
  <v-snackbar
    v-model="snackbar"
    :color="actual ? actual.color : null"
    variant="tonal"
  >
    {{ actual.text }}

    <template v-slot:actions>
      <v-btn
        variant="text"
        @click="handleChange"
        text
        icon="mdi-close"
      >
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    snackbar: false,
    timer: null,
    actual: null,
    timeout: 2000,
    pending: [],
  }),
  computed: {
    ...mapState(['alerts']),
  },
  watch: {
    alerts () {
      if (this.alerts) {
        this.pending.push(...this.alerts)
        this.$store.state.alerts = null
        if (!this.snackbar) this.handleChange()
      }
    },
  },
  mounted () {
    this.handleChange()
  },
  methods: {
    handleChange () {
      this.snackbar = false
      if (this.timer) clearTimeout(this.timer)
      if (this.pending.length > 0) {
        this.actual = this.pending.shift()
        this.snackbar = true
        this.timer = setTimeout(this.handleChange, this.actual && this.actual.timeout ? this.actual.timeout : this.timeout)
      }
    },
  },
}
</script>

