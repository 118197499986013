import store from '@/plugins/store/index'
import router from '@/router'

export default {
  setUser (v) {
    store.state.user = v
    localStorage.setItem(`u`, v ? JSON.stringify(v) : '')
    return
  },
  getUser () {
    let user = store.state.user
    if (user) return user
    const aux = localStorage.getItem(`u`)
    user = aux && aux.length > 0 ? JSON.parse(aux) : null
    store.state.user = user
    if (user) return user
    else router.push('/login')
  },
  setManagementWorkspace (v) {
    store.state.managementWorkspaceID = v
    localStorage.setItem(`managementWorkspace`, v ? v : '')
    return
  },
  getManagementWorkspace () {
    const aux = localStorage.getItem(`managementWorkspace`)
    return aux && aux.length > 0 ? aux : null
  },
  validateEmail (v) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) // eslint-disable-line no-useless-escape
  },
  checkPassword (v) { 
    /*
    The password is at least 8 characters long (?=.{8,}).

    The password has at least one uppercase letter (?=.*[A-Z]).

    The password has at least one lowercase letter (?=.*[a-z]).

    The password has at least one digit (?=.*[0-9]).

    The password has at least one special character ([^A-Za-z0-9]).
    */
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    return strongPassword.test(v) 
  }
}

