import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/plugins/store/index'

// Vuetify
import 'vuetify/styles'
import vuetify from '@/plugins/vuetify'

import i18n from '@/plugins/i18n/index.js'


createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(store)
  .mount('#app')

